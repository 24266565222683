<template>
<div class="page">
  <div class="card">
    <p style="width: 100%;text-align: center">专家行业分布图</p>
    <Chart type="pie" :data="expertIndustry" />
  </div>
  <div class="card">
    <p style="width: 100%;text-align: center">智库增长趋势图</p>
    <Legend name="新入驻专家数"></Legend>
    <Chart type="line" :options="{legend: {display:false}}" :data="expertNumber"/>
  </div>
</div>
</template>

<script>
import Legend from "@/views/platform/dataDiagram/legend";
export default {
  name: "expertDiagram",
  components: {Legend},
  data() {
    return {
      expertIndustry:null,
      expertNumber:null
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    getDate() {

      this.$http.get('/Statistics/expert').then((res) => {


        let expertIndustry = res.data.expertIndustry
        let labels = []
        let data = []

        for (let i = 0; i < expertIndustry.length; i++) {
          labels.push(expertIndustry[i].industry)
          data.push(expertIndustry[i].total)
        }
        this.expertIndustry = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726"
              ],
              hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
              ]
            }
          ]
        }
        let expertNumber = res.data.expertNumber
        labels = []
        data = []
        for (let i = 0; i < expertNumber.length; i++) {
          labels.push(expertNumber[i].months)
          data.push(expertNumber[i].count)
        }
        this.expertNumber = {
          labels: labels,
          datasets: [
            {
              label: '新入驻专家数量',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
      })
    }
  }
}
</script>

<style scoped>

.card{
  width: calc(50% - 7px);
}
.page{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: none;
  border: none;
}
</style>